import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ContentService } from '../services/content.service';
import { EncService } from '../services/enc.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  user: any;
  profileImage: string = 'assets/images/circle-icons-profile.png';
  path: string | undefined;

  constructor(
    private firestore: AngularFirestore,
    private contentService: ContentService,
    private storageService: AngularFireStorage,
    private encService: EncService
  ) {}

  ngOnInit(): void {
    this.contentService.hideMenu.next(false);

    const Id = localStorage.getItem('token');

    this.firestore
      .collection('users')
      .doc(Id)
      .valueChanges()
      .pipe(take(1))
      .subscribe((user: any) => {
        // console.log(user[0]);
        const userData = { ...this.encService.decrypt(user.data), ...user };
        this.user = userData;
        if (this.user.profileImage) {
          this.profileImage = this.user.profileImage;
        }
      });
  }

  uploadImage($event) {
    this.path = $event.target.files[0];

    this.storageService
      .upload(
        'Profile Pics/' + localStorage.getItem('token') + '/profilePic',
        this.path
      )
      .then(
        (response: any) => {
          // console.log(response);
          response.ref.getDownloadURL().then((downloadURL: string) => {
            // console.log(downloadURL);
            this.profileImage = downloadURL;
            this.firestore
              .doc('users/' + localStorage.getItem('token'))
              .update({ profileImage: downloadURL });
          });
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  ngOnDestroy() {
    this.contentService.hideMenu.next(true);
  }
}
