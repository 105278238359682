import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class EncService {
  private ENCRYPTION_KEY = 'jhsd9i#$39h@ef-su80rqw3do';

  encrypt(value: any) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(value),
      this.ENCRYPTION_KEY
    ).toString();
  }

  decrypt(value: any) {
    const bytes = CryptoJS.AES.decrypt(value, this.ENCRYPTION_KEY);
    return bytes.toString()
      ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      : value;
  }
}
