import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ContentService } from '../services/content.service';
import { EncService } from '../services/enc.service';
import { AngularFireAuth } from '@angular/fire/auth';
// import moment from 'moment';

declare var google: any;

@Component({
  selector: 'app-show-result',
  templateUrl: './show-result.component.html',
  styleUrls: ['./show-result.component.scss'],
})
export class ShowResultComponent implements OnInit, AfterViewInit {
  patient: any;
  showOnlyOneResult = false;

  generatedLink: string;

  chartData = {
    type: 'LineChart',
    data: [
      // ["Jan", 500],
      // ["Feb", 800],
      // ["Mar", 400],
      // ["Apr", 600],
      // ["May", 400],
      // ["Jun", 750],
      // ["Jul", 800],
      // ["Aug", 810],
      // ["Sep", 820],
      // ["Oct", 900],
      // ["Nov", 910],
      // ["Dec", 920]
    ],
    columnNames: ['Month', 'Control Level'],
    options: {
      hAxis: {
        title: 'Date',
      },
      vAxis: {
        title: 'Control Level',
        allowDecimals: false,
        ticks: [1, 2, 3],
      },
      pointSize: 5,
    },
    width: 392,
    height: 400,
  };

  conditionValues = {
    Uncontrolled: 1,
    'Partly Controlled': 2,
    'Partially Controlled': 2,
    'Well Controlled': 3,
  };

  constructor(
    private route: ActivatedRoute,
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private contentService: ContentService,
    private router: Router,
    private encService: EncService
  ) {}

  ngOnInit(): void {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.loadPreviousResult();
      } else {
        console.log('User is signed out');
      }
    });
  }

  loadPreviousResult() {
    this.route.params.subscribe((params: Params) => {
      const Id = params.id;
      this.generatedLink = `https://azma.live/show-result/${Id}`;
      this.firestore
        .collection('users')
        .doc(Id)
        .valueChanges()
        .pipe(take(1))
        .subscribe((user: any) => {
          const userData = this.encService.decrypt(user.data);
          if (userData) {
            this.patient = userData;
            this.patient.assessments.reverse();

            this.route.queryParams.subscribe((params: any) => {
              if (params.ext === true || params.ext === 'true') {
                this.showOnlyOneResult = true;
                this.patient.assessments = this.patient.assessments.slice(0, 1);
              } else {
                this.patient.assessments
                  .slice(0, 10)
                  .reverse()
                  .forEach((element) => {
                    this.chartData.data.push([
                      new Date(element.time).toLocaleString('en-us', {
                        month: 'long',
                        year: 'numeric',
                        day: 'numeric',
                      }),
                      this.conditionValues[element.type],
                    ]);
                  });
              }
            });
          } else {
            this.router.navigate(['']);
          }
        });

      setTimeout(() => {
        this.chartData.width =
          document.getElementById('map-col')?.clientWidth - 40;
      }, 2000);
    });
  }

  ngAfterViewInit() {
    // Listen for orientation changes
    window.addEventListener(
      'orientationchange',
      () => {
        // Announce the new orientation number
        // alert(window.orientation);
        setTimeout(() => {
          this.chartData.width =
            document.getElementById('map-col')?.clientWidth - 40;
        }, 500);
      },
      false
    );
  }

  /* To copy Text from Textbox */
  copyLink() {
    navigator.clipboard
      .writeText(this.generatedLink)
      .then(() => {
        this.contentService.displaySuccess(
          'Link Copied Successfully \n تم نسخ الرابط بنجاح'
        );
      })
      .catch((error) => {
        this.contentService.displayError(error);
      });
  }
}
