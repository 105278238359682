<div class="container helper-div min-height-100" *ngIf="contactPageContent">
  <span class="helper"></span>
  <div class="vertical-center">
    <div class="row">
      <div class="col-12 mx-auto" style="border-radius: 50px">
        <div class="text-center mb-4">
          <h1 class="text-white" translate>Contact Us</h1>
        </div>

        <div class="row">
          <div class="col-md-6 col-12 helper-div">
            <span class="helper"></span>
            <div
              class="p-4 border-right-white force-ltr vertical-center"
              style="height: fit-content"
            >
              <h4 class="text-white mb-3">{{ contactPageContent.header }}</h4>

              <p class="text-white">{{ contactPageContent.description }}</p>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="py-5 px-4">
              <h4 class="text-white mb-4" translate>Contact Us</h4>

              <p class="text-white mb-4">
                <i class="ri-map-pin-2-fill text-white"></i>
                {{ contactPageContent.address }}
              </p>

              <p class="text-white">
                <i class="ri-phone-fill text-white"></i>
                {{ contactPageContent.phoneNumber }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row w-100 mt-5">
      <div class="col-md-6 col-xl-3 col-12 mx-auto">
        <button
          class="btn btn-secondary bg-transparent text-white w-100"
          [routerLink]="['']"
          translate
        >
          Back
        </button>
      </div>
    </div>
  </div>
</div>
