<div class="container helper-div min-height-100">
    <span class="helper"></span>
    <div class="vertical-center force-ltr">
        <div class="w-100">
            <div class="row mb-4 w-100">
                <div class="col-md-2 mx-auto col-4 text-center">
                    <img src="assets/images/azma-logo.svg" alt="Azma - Logo" class="w-75">
                </div>
            </div>

            <div class="row my-5 w-100">
                <div class="col-md-4 text-center mx-auto">
                    <p class="text-white" translate>Select Your Lanuage</p>

                    <button class="d-block w-75 cairo btn ar-button mx-auto my-3"
                        (click)="changeLanguage('ar')">العربية</button>
                    <button class="d-block w-75 cairo btn en-button mx-auto my-3"
                        (click)="changeLanguage('en')">English</button>

                </div>
            </div>

            <!-- <div class="row mt-4 w-100">
                <div class="col-md-3 mx-auto col-12">
                    <button class="w-100 btn btn-primary" [routerLink]="['/login']"
                        translate>NEXT</button>
                </div>
            </div> -->
        </div>

    </div>
</div>