<div class="container helper-div min-height-100">
    <span class="helper"></span>
    <div class="vertical-center" *ngIf="faq">
        <div class="row text-center">
            <h1 class="text-white w-100">{{ faq.title }}</h1>
        </div>

        <div class="row w-100 mt-5">
            <div class="col-md-10 col-12 mx-auto">
                <iframe width="100%" height="525" id="youTubePlayer" class="desktop-only"
                    [src]=" 'https://www.youtube.com/embed/' + faq.youTubeVideoId + '?&rel=0&controls=1&frameborder=0&modestbranding=1&loop=1&playlist=' + faq.youTubeVideoId | safe"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>


                    <iframe width="100%" height="200" id="youTubePlayer" class="mobile-only"
                    [src]=" 'https://www.youtube.com/embed/' + faq.youTubeVideoId + '?&rel=0&controls=1&frameborder=0&modestbranding=1&loop=1&playlist=' + faq.youTubeVideoId | safe"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>

        <div class="row w-100 mt-5">
            <div class="col-md-6 col-xl-3 col-12 mx-auto">
                <button class="btn btn-secondary bg-transparent text-white w-100"
                    [routerLink]="['/childhood-faqs']" translate>Back</button>
            </div>
        </div>

    </div>
</div>