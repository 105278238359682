import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.authService.checkForIntro()) {
      this.router.navigate(['/intro']);
      return false;
    }

    // if (!this.authService.checkForUser()) {
    //   this.router.navigate(['/login']);
    //   return false;
    // }

    // return this.authService.checkForUser();

    this.authService.checkForUser().subscribe(response => {
      // console.log(response);
      if (response) {
        return true;
      } else {
        this.router.navigate(['/intro']);
      }
    })

    return true

  }
}
