import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ArticleComponent } from './articles/article/article.component';
import { ArticlesComponent } from './articles/articles.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ChildhoodFaqComponent } from './childhood-faqs/childhood-faq/childhood-faq.component';
import { ChildhoodFaqsComponent } from './childhood-faqs/childhood-faqs.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { IntroVideoComponent } from './intro-video/intro-video.component';
import { IntroComponent } from './intro/intro.component';
import { MessageComponent } from './messages/message/message.component';
import { MessagesComponent } from './messages/messages.component';
import { ProfileComponent } from './profile/profile.component';
import { QuizComponent } from './quiz/quiz.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ShowResultComponent } from './show-result/show-result.component';

const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [AuthGuardService]},
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'intro', component: IntroComponent},
  {path: 'about', component: AboutComponent},
  {path: 'contact-us', component: ContactUsComponent},
  {path: 'show-result/:id', component: ShowResultComponent},
  {path: 'assessment', component: QuizComponent, canActivate: [AuthGuardService]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService]},
  {path: 'articles', component: ArticlesComponent, canActivate: [AuthGuardService]},
  {path: 'article/:name', component: ArticleComponent, canActivate: [AuthGuardService]},
  {path: 'messages-to-asthma-patients', component: MessagesComponent, canActivate: [AuthGuardService]},
  {path: 'message-to-asthma-patients/:name', component: MessageComponent, canActivate: [AuthGuardService]},
  {path: 'childhood-faqs', component: ChildhoodFaqsComponent, canActivate: [AuthGuardService]},
  {path: 'childhood-faq/:name', component: ChildhoodFaqComponent, canActivate: [AuthGuardService]},
  {path: 'intro-video', component: IntroVideoComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
