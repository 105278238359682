import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {

  article: any;

  gender: string = localStorage.getItem('gender') ? localStorage.getItem('gender') : 'male';
  currentAudio: any;
  audioState: boolean = false;

  constructor(
    private contentService: ContentService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.contentService.hideMenu.next(false);

    this.route.params.subscribe((params: Params) => {
      const slug = params['name'];

      this.contentService.getMessages().then((response: any) => {

        this.article = response.find(article => article.fields.slug == slug).fields;

        // console.log(this.article);
      })

    })
  }

  convertRichTextIntoHTML(richText: any) {
    return this.contentService.returnHtmlFromRichText(richText);
  }

  playAudio(audioLink: any) {
    this.currentAudio = new Audio(audioLink);
    this.currentAudio.play();
    this.audioState = true;
  }

  pauseAudio() {
    this.currentAudio?.pause();
    this.audioState = false;
  }

  ngOnDestroy() {
    this.contentService.hideMenu.next(true);
    this.pauseAudio();
  }

}
