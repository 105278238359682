<div class="container helper-div min-height-100">
    <span class="helper"></span>
    <div class="vertical-center">

        <div class="row text-center">
            <h1 class="text-white w-100" translate>Childhood Asthma FAQs</h1>
        </div>

        <div class="row">
            <div class="col-md-8 mx-auto col-12" *ngIf="faqs">
                <div class="article row w-100 bg-white my-4 helper-div pointer px-4 py-2 mx-auto" style="border-radius: 10px;" *ngFor="let faq of faqs" [routerLink]="['/childhood-faq', faq.fields.slug]">
                    <span class="helper"></span>
                    <div class="vertical-center">
                        <div class="row">
                            <div class="col-xl-11 col-10 helper-div">
                                <span class="helper"></span>
                                <h5 class="secondary-color vertical-center mb-0">{{faq.fields.title}}</h5>
                            </div>
                            <div class="col-xl-1 col-2 helper-div">
                                <span class="helper"></span>
                                <i class="ri-arrow-right-s-line ri-2x secondary-color font-weight-bold vertical-center" *ngIf="lang == 'en'"></i>
                                <i class="ri-arrow-left-s-line ri-2x secondary-color font-weight-bold vertical-center" *ngIf="lang == 'ar'"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row w-100 mt-5">
            <div class="col-md-6 col-xl-3 col-12 mx-auto">
                <button class="btn btn-secondary bg-transparent text-white w-100" [routerLink]="['']" translate>Back</button>
            </div>
        </div>

    </div>
</div>