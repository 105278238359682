import { Component, OnInit, TemplateRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { AuthService } from 'src/app/services/auth.service';
import { ContentService } from 'src/app/services/content.service';
import { POLICIES_AR, POLICIES_EN } from './policies-content';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, Validators.required),
    birth_date: new FormControl(null, Validators.required),
    gender: new FormControl('', Validators.required),
    country: new FormControl('Saudi Arabia', Validators.required),
    phone: new FormControl(null, Validators.required),
    uid: new FormControl(this.firestore.createId()),
    privacyCheck: new FormControl(false),
  });

  countries: any = this.contentService.getCountries();

  disabled: boolean = false;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.SaudiArabia];

  errorMessage: string = '';
  modalRef: BsModalRef;
  privacyPolicies =
    localStorage.getItem('lang') === 'ar' ? POLICIES_AR : POLICIES_EN;
  constructor(
    private authService: AuthService,
    private contentService: ContentService,
    private firestore: AngularFirestore,
    private router: Router,
    private modalService: BsModalService
  ) {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit(): void {}

  register() {
    this.registerForm.markAllAsTouched();

    // console.log(this.registerForm.value);

    if (this.registerForm.status == 'VALID') {
      this.disabled = true;

      this.authService.register(this.registerForm.value).then(
        (response) => {
          console.log({ register: response });

          localStorage.setItem('token', response.user.uid);
          localStorage.setItem('gender', this.registerForm.get('gender').value);

          this.registerForm.patchValue({
            uid: response.user.uid,
          });
          let body = {
            ...this.registerForm.value,
            age: this.calculateAge(this.registerForm.get('birth_date').value),
            assessments: [],
          };
          body.phone = this.registerForm.value.phone.e164Number;
          body.password = 'Classified';
          // console.log(body);

          this.authService
            .saveUserToDatabase(body, this.registerForm.get('uid').value)
            .then(
              (value) => {
                // console.log(value);

                this.router.navigate(['/intro-video']);
              },
              (error) => {
                // console.log(error);
                this.disabled = false;
              }
            );
        },
        (error) => {
          // console.log(error);
          this.errorMessage = error.message;
          this.disabled = false;
        }
      );
    }
  }

  calculateAge(birthday) {
    var today = new Date();
    var birthDate = new Date(birthday);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}
