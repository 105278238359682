<div class="container helper-div min-height-100">
    <span class="helper"></span>
    <div class="vertical-center">
        <div class="row">
            <div class="col-md-8 col-12 mx-auto text-center">
                <h1 class="text-white text-uppercase" translate>Register</h1>

                <form [formGroup]="registerForm" (ngSubmit)="register()" class="w-100 row">
                    <div class="my-2 col-md-6 col-12">
                        <input type="text" class="w-100 form-control" [formControlName]="'name'"
                            placeholder="Name / الاسم" />
                        <span class="w-100 text-white d-block text-left" *ngIf="
                registerForm.get('name').invalid &&
                (registerForm.get('name').touched ||
                  registerForm.get('name').dirty)
              " translate>Required</span>
                    </div>

                    <div class="my-2 col-md-6 col-12">
                        <input type="email" class="w-100 form-control" [formControlName]="'email'"
                            placeholder="Email Address / البريد الإلكتروني" />
                        <span class="w-100 text-white d-block text-left" *ngIf="
                registerForm.get('email').invalid &&
                (registerForm.get('email').touched ||
                  registerForm.get('email').dirty)
              " translate>Invalid Email Address</span>
                    </div>

                    <div class="my-2 col-md-6 col-12">
                        <input type="password" class="w-100 form-control" [formControlName]="'password'"
                            placeholder="Password / كلمة السر" />
                        <span class="w-100 text-white d-block text-left" *ngIf="
                registerForm.get('password').invalid &&
                (registerForm.get('password').touched ||
                  registerForm.get('password').dirty)
              " translate>Required</span>
                    </div>

                    <div class="my-2 col-md-6 col-12">
                        <input type="text" #dateInput class="w-100 form-control" [formControlName]="'birth_date'"
                            placeholder="Date of Birth / تاريخ الميلاد" (click)="dateInput.type = 'date'" />
                        <span class="w-100 text-white d-block text-left" *ngIf="
                registerForm.get('birth_date').invalid &&
                (registerForm.get('birth_date').touched ||
                  registerForm.get('birth_date').dirty)
              " translate>Required</span>
                    </div>

                    <div class="my-2 col-md-6 col-12">
                        <select [formControlName]="'gender'" class="w-100 form-control py-0 px-3" name="gender"
                            style="height: 50px">
                            <option value="" selected disabled translate>
                                Select Gender
                            </option>
                            <option value="male" translate>Male</option>
                            <option value="female" translate>Female</option>
                        </select>

                        <span class="w-100 text-white d-block text-left" *ngIf="
                registerForm.get('gender').invalid &&
                (registerForm.get('gender').touched ||
                  registerForm.get('gender').dirty)
              " translate>Required</span>
                    </div>

                    <div class="my-2 col-md-6 col-12">
                        <select [formControlName]="'country'" class="w-100 form-control py-0 px-3" name="country"
                            style="height: 50px">
                            <option value="" selected disabled translate>
                                Select Country
                            </option>
                            <option translate *ngFor="let country of countries" [value]="country">
                                {{ country }}
                            </option>
                        </select>

                        <span class="w-100 text-white d-block text-left" *ngIf="
                registerForm.get('country').invalid &&
                (registerForm.get('country').touched ||
                  registerForm.get('country').dirty)
              " translate>Required</span>
                    </div>

                    <div class="my-2 col-md-6 col-12 force-ltr">
                        <ngx-intl-tel-input [cssClass]="'form-control'" id="reservation-phone"
                            [preferredCountries]="preferredCountries" [enablePlaceholder]="true"
                            [searchCountryFlag]="true" [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.SaudiArabia" [maxLength]="15"
                            [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phone"
                            [formControlName]="'phone'">
                        </ngx-intl-tel-input>
                        <span class="w-100 text-white d-block text-left" *ngIf="
                registerForm.get('phone').invalid &&
                registerForm.get('phone').touched
              " translate>Required</span>
                    </div>

                    <div class="row w-100" *ngIf="errorMessage">
                        <div class="my-2 mx-auto">
                            <p class="text-white" translate>{{ errorMessage }}</p>
                        </div>
                    </div>
                    <div class="row w-100 check_privacty_container">
                        <input type="checkbox" formControlName="privacyCheck" />
                        <span translate>check_privacy_prefix</span>
                        <span translate (click)="openModal(template)">check_privacy_link</span>
                    </div>
                    <div class="row w-100">
                        <div class="my-2 col-md-6 col-12 mx-auto">
                            <button class="btn btn-primary text-uppercase w-100" type="submit"
                                [disabled]="!registerForm.value.privacyCheck" translate>
                                Next
                            </button>
                        </div>
                    </div>
                </form>

                <div class="my-2">
                    <hr class="w-25 bg-white mx-auto" style="border-top: 1px solid white" />
                </div>

                <div>
                    <a class="text-white" [routerLink]="['/login']" translate>Already have an account? Log In</a>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body">
        <div *ngFor="let item of privacyPolicies" class="privcy_item">
            <h3>{{ item.title }}</h3>
            <p>{{ item.content }}</p>
            <ul *ngIf="item.polits">
                <li *ngFor="let polit of item.polits">{{ polit }}</li>
            </ul>
            <p>{{ item.footer }}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modalRef.hide()" translate>
            close_policy
        </button>
    </div>
</ng-template>