import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-childhood-faq',
  templateUrl: './childhood-faq.component.html',
  styleUrls: ['./childhood-faq.component.scss']
})
export class ChildhoodFaqComponent implements OnInit {

  faq: any;

  constructor(
    private contentService: ContentService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.contentService.hideMenu.next(false);

    this.route.params.subscribe((params: Params) => {
      const slug = params['name'];

      this.contentService.getChildhoodFaqs().then((response: any) => {

        this.faq = response.find(faq => faq.fields.slug == slug).fields;

        // console.log(this.faq);
      })

    })
  }

}
