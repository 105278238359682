export const POLICIES_AR = [
  {
    title: 'نظرة عامة على سياسة الخصوصية',
    content:
      'نحن ملتزمون بحماية واحترام خصوصيتك. توضح هذه السياسة كيفية استخدامنا لأي معلومات شخصية تقدمها لنا عبر موقعنا (https://azma.live)',
  },
  {
    title: 'جمع ومعالجة معلوماتك الشخصية',
    content:
      'لغرض سياسة الخصوصية، Creatives and Developers هي الجهة المتحكمة في بياناتك الشخصية. يعتمد أساسنا القانوني لجمع واستخدام معلوماتك الشخصية، كما هو موضح في هذه السياسة، على المعلومات التي نقوم بجمعها والسياق الخاص الذي نقوم بجمعها فيه. قد نعالج معلوماتك الشخصية لأن:',
    polits: [
      'نحن بحاجة إلى أداء عقد معك، مثل عند إنشاء سياسة معنا',
      'قدمت لنا إذنًا للقيام بذلك',
      'المعالجة في مصلحتنا المشروعة ولا تتجاوز حقوقك',
      'الامتثال للقانون',
    ],
  },
  {
    title: 'فئات المعلومات الشخصية التي نقوم بجمعها',
    content:
      'نقوم بجمع الفئات التالية من المعلومات الشخصية التي تختار تقديمها لنا على موقعنا (https://azma.live)',
    polits: [
      'عنوان البريد الإلكتروني',
      'الاسم الأول والاسم الأخير',
      'رقم الهاتف',
      'الجنس',
      'تاريخ الميلاد',
      'نتائج استبيان الربو',
    ],
    footer: `نسبة لحقوقك المتعلقة بمعلوماتك الشخصية، يرجى مراجعة قسم "ما هي حقوقك كمالك للمعلومات الشخصية" أدناه.`,
  },
  {
    title: 'كيفية جمع بياناتك الشخصية',
    content: 'نقوم بجمع البيانات بطريقتين:',
    polits: ['البيانات التي تقدمها لنا من خلال التسجيل أو الاستبيان'],
    footer:
      "نحن، كمطورين طرف ثالث للموقع، سنقوم فقط بإدراج ملفات تعريف الارتباط على الموقع لحفظ تفاصيل تسجيل الدخول الخاصة بك لتجنب الحاجة إلى تسجيل الدخول في كل مرة. نجمع موافقتك بمجرد النقر على 'موافق' في سياسة الخصوصية أثناء التسجيل.",
  },
  {
    title: 'مع من نشارك المعلومات الشخصية المجمعة',
    content: `كمطور المنصة، Creatives and Developers سنقدم البيانات المجمعة للجمعية السعودية لطب وجراحة الصدر للتواصل والدعم المخصص بك. يتم استضافة بيانات التسجيل الخاصة بك على منصة الطرف الثالث لاستضافة موقعنا ، والذي يقدم أيضًا تحليلات حول كيفية استخدام التطبيق والنتائج المشاركة مع الجمعية السعودية لطب وجراحة الصدر لدعمك.`,
  },
  {
    title: 'ما هي حقوقك كمالك للمعلومات الشخصية',
    content: `لديك حقوق حماية البيانات التالية:
إلغاء وحذف الحساب
كيف يمكنك ممارسة حقوقك كمالك للمعلومات الشخصية
يمكنك تقديم طلباتك لممارسة حقوقك في إطار قوانين حماية البيانات العامة (GDPR) وقانون الحماية الاستهلاكية للولايات المتحدة (CCPA) عبر:`,
    polits: [
      'البريد الإلكتروني: admin@creativesad.com',
      'الموقع: (https://azma.live)',
    ],
    footer: `يرجى ملاحظة أنه قد نطلب منك التحقق من هويتك قبل الرد على مثل هذه الطلبات.
لديك الحق في تقديم شكوى إلى هيئة حماية البيانات بشأن جمعنا لمعلوماتك الشخصية واستخدامها. لمزيد من المعلومات، يرجى الاتصال بهيئة حماية البيانات المحلية لديك`,
  },
  {
    title: 'موقع ونقل معلوماتك الشخصية',
    content:
      'نستخدم خدمات طرف ثالث في بلجيكا لتخزين البيانات، ويتم الحفاظ على سرية معلوماتك الشخصية واستخدامها فقط للأغراض المذكورة أعلاه من قبل مقدم الخدمة، وذلك وفقًا لهذه السياسة. من خلال قبول هذه السياسة الخصوصية، فإنك توافق على مثل هذا الاستخدام لمعلوماتك الشخصية من قبل مقدمي الخدمة الطرف الثالث. سنتخذ جميع الخطوات اللازمة لضمان معالجة بياناتك بشكل آمن وفقًا لسياسة الخصوصية هذه، ولن يتم نقل بياناتك الشخصية إلى مؤسسة أو بلد إلا إذا كانت هناك ضوابط كافية لضمان أمان بياناتك الشخصية.',
  },
  {
    title: 'تخزين وحذف معلوماتك الشخصية',
    content: `نحن ملتزمون بحماية بياناتك الشخصية. سيتم الاحتفاظ ببيانات الاستخدام لغرض دعم للجمعية السعودية لطب وجراحة الصدر المخصص، وسيتم تخزين معلوماتك الشخصية بشكل آمن خلال فترة تقديم الخدمة لك.
بعد ذلك، سنقوم بمسح جميع معلوماتك الشخصية التي لدينا، إلا عندما نحتاج إلى الاحتفاظ بها لفترة أطول للامتثال للقانون المعمول به أو حل النزاعات. بعد حذف معلوماتك الشخصية من قاعدة بياناتنا، سيتم حذف البيانات الاحتياطية ذات الصلة، عادةً خلال 7 أيام.`,
  },
  {
    title: 'أمان معلوماتك الشخصية',
    content:
      'نتخذ التدابير التقنية المناسبة للحفاظ على سرية معلوماتك الشخصية وحمايتها من الإتلاف أو الفقدان العرضي أو غير القانوني، أو التغيير، أو الكشف الغير المصرح به. نستخدم تدابير الحماية التقنية بما في ذلك',
    polits: [
      'التشفير: يتم تعزيز بياناتك ببروتوكولات التشفير، مما يضمن حمايتها أثناء النقل والتخزين.',
    ],
    footer: `أمان معلوماتك الشخصية يعتمد أيضًا على حمايتك لحسابك. يرجى استخدام كلمة مرور فريدة وقوية، والحفاظ على سرية بيانات تسجيل الدخول الخاصة بك. كما يرجى تسجيل الخروج بعد استخدام خدماتنا من اي جهاز كمبيوتر مشترك.`,
  },
  {
    title: 'حماية خصوصية طفلك',
    content:
      "لا تستهدف خدمتنا أي شخص دون سن 16عام ('الأطفال'). نحن لا نقوم عن عمد بجمع معلومات التعريف الشخصي من أي شخص دون سن 18 عام. إذا كنت والد أو وصيًا وعلى علم بأن قد قام طفلك بتقديم أي بيانات شخصية له، فالرجاء التواصل معنا حالا. إذا تبين لنا أننا قد قمنا بجمع بيانات شخصية من الأطفال دون التحقق من موافقة الوالدين، فإننا نتخذ خطوات لإزالة تلك المعلومات من خوادمنا.",
  },
  {
    title: 'تغييرات في سياسة الخصوصية هذه',
    content:
      "من وقت لآخر قد نقوم بتحديث سياسة الخصوصية هذه. بحيث سنقوم بتحديث الأقسام 'آخر تحديث' في أعلى سياسة الخصوصية. إذا قمنا بإجراء تغييرات جوهرية في هذه السياسة، قد نعلمك عبر مدونة موقعنا، أو عبر البريد الإلكتروني، أو عن طريق أي طريقة نحددها. سيخضع استخدامك المستمر لهذا الموقع أو خدمتنا و/أو توفير معلومات لنا إلى شروط سياسة الخصوصية السارية في ذلك الوقت.",
  },
  {
    title: 'اتصل بنا',
    content:
      'إذا كانت لديك أي أسئلة حول ممارسات الخصوصية الخاصة بنا أو هذه السياسة، يرجى الاتصال بنا على ',
    polits: [
      'البريد الإلكتروني: admin@creativesad.com',
      'الموقع الإلكتروني: (https://azma.live) ',
    ],
  },
];

export const POLICIES_EN = [
  {
    title: 'Privacy policy overview',
    content:
      'We are committed to protecting and respecting your privacy. This policy explains how we use any personal information that you provide to us through our website (https://azma.live)',
  },
  {
    title: 'Collecting and processing your personal information',
    content:
      'For the purpose of this Privacy Policy, Creatives and Developers is a Data Controller of your personal information. Our legal basis for collecting and using your personal information, as described in this Privacy Policy, depends on the information we collect and the specific context in which we collect it. We may process your personal information because:',
    polits: [
      'We need to perform a contract with you, such as when you create a Policy with us',
      'You have given us permission to do so',
      "The processing is in our legitimate interests and it's not overridden by your rights",
      'For payment processing purposes',
      'To comply with the law',
    ],
  },
  {
    title: 'What categories of personal information we collect',
    content:
      'We collect the following categories of personal information that you choose to provide us on our website (https://azma.live)',
    polits: [
      'Email Address',
      'First name and last name',
      'Phone number',
      'Gender',
      'Date of Birth',
      'Results of the Asthma Questionnaire',
    ],
    footer: `For your rights regarding your personal information, please see "What are your rights as the owner of the personal information" section below.`,
  },
  {
    title: 'How we collect your personal data',
    content: 'We collect data in two ways:',
    polits: [
      'Data that you provide to us through the registration or questionnaire',
    ],
    footer:
      'We, as the third party developer of the website, will only insert cookies on the website  to save your log-in details to avoid having you log-in every time. We collect your consent once you click ‘Accept’ on our Privacy policy at registration.',
  },
  {
    title: 'With whom we share the collected personal information',
    content: `As the developer of the platform, Creatives and Developers will provide the Saudi Thoracic Society with the data collected for customized communication and support. Your registered data is hosted on our 3rd party platform partner, which also provides analytics on how the app usage and results shared with Thoracic society to support you.`,
  },
  {
    title: 'What are your rights as the owner of personal information',
    content: `You have the following data protection rights:
Decativate and delete actount
How can you exercise your rights as the owner of personal information
You may submit your requests to exercise your right under the GDPR and CCPA by:`,
    polits: ['Email: admin@creativesad.com', 'Website: (https://azma.live)'],
    footer:
      'Please note that we may ask you to verify your identity before responding to such requests.You have the right to complain to a Data Protection Authority about our collection and use of your personal information. For more information, please contact your local data protection authority',
  },
  {
    title: 'Location and transfer of your personal information',
    content:
      'We use third party service providers’ servers in Belgium to store the data, All such service providers are contractually bound to keep your personal information secure and confidential and to use it only for the above purposes, consistent with this Privacy Policy. By accepting this Privacy Policy, you consent to such use of your personal information by these third party service providers. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.',
  },
  {
    title: 'Storage and deletion of your personal information',
    content: `We are committed to safeguarding your personal data. Usage data will be retained for the sole purpose of Saudi Thoracic Society’s customized support, and your personal information will be securely stored for the duration of our service provision to you. After that, we will erase all your personal information that we have, except when we need to keep such information for a longer period to comply with applicable law or resolve disputes. Following the deletion of your personal information from our database, the related backup data will be deleted, generally within 7 days.`,
  },
  {
    title: 'Security of your personal information',
    content:
      'We take appropriate technical measures for keeping your personal information confidential and protected against accidental or unlawful destruction or loss, alteration, unauthorised disclosure or access. We use technical safeguards measures including',
    polits: [
      'Encryption: your data is fortified with encryption protocols, ensuring it remains protected during transmission and storage',
    ],
    footer: `The security of your personal information also depends on your protection of your user account. Please use a unique and strong password, and keep your login credentials a secret. Also, be sure to log out after having used our services from a shared computer.`,
  },
  {
    title: 'Protecting your child’s privacy',
    content:
      "Our Service does not address anyone under the age of 16 ('Children'). We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children have provided us with Personal Data, please contact us. If we become aware that we have collected personal data from children without verification of parental consent, we take steps to remove that information from our servers.",
  },
  {
    title: 'Changes to this privacy policy',
    content:
      'From time to time we may update this Privacy Policy. If we do, we will update the “last updated” sections at the top of the Privacy Policy. If we make material changes to this policy, we may notify you on our website, by a blog post, by email, or by any method we determine. Your continued use of this website or our service and/or continued provision of information to us will be subject to the terms of the then-current Privacy Policy.',
  },
  {
    title: 'Contact us ',
    content:
      'If you have any questions about our Privacy Practices or this Policy, please contact us at ',
    polits: ['Email: admin@creativesad.com', 'Website: (https://azma.live) '],
  },
];
