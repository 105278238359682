<div class="container helper-div min-height-100">
    <span class="helper"></span>
    <div class="vertical-center" *ngIf="article">
        <div class="row text-center">
            <h1 class="text-white w-100">{{ article.title }}</h1>
        </div>

        <div class="row w-100 mt-5">
            <div class="col-md-12 col-12 mx-auto">
                <div class="row w-100">
                    <div class="col-md-6 col-12 position-relative">
                        <img [src]="article.headImage.fields.file.url" [alt]="article.headImage.fields.title" class="w-100">

                        <div class="speaker position-absolute bg-primary rounded-circle text-center pointer" *ngIf="gender == 'male' && !audioState" (click)="playAudio(article.maleAudio.fields.file.url)">
                            <i class="ri-volume-up-line text-white ri-2x"></i>
                        </div>

                        <div class="speaker position-absolute bg-primary rounded-circle text-center pointer" *ngIf="gender == 'female' && !audioState" (click)="playAudio(article.femaleAudio.fields.file.url)">
                            <i class="ri-volume-up-line text-white ri-2x"></i>
                        </div>

                        <div class="speaker position-absolute bg-primary rounded-circle text-center pointer" *ngIf="audioState" (click)="pauseAudio()">
                            <i class="ri-stop-fill text-white ri-2x"></i>
                        </div>

                    </div>

                    <div class="col-md-6 col-12">
                        <div [innerHTML]="convertRichTextIntoHTML(article.content)" class="text-white inner-white" #textDiv></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row w-100 mt-5">
            <div class="col-md-6 col-xl-3 col-12 mx-auto">
                <button class="btn btn-secondary bg-transparent text-white w-100" [routerLink]="['/articles']" translate> Back</button>
            </div>
        </div>

    </div>
</div>