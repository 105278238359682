import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperComponent, SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { AuthService } from '../services/auth.service';
import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit, OnDestroy {

  @ViewChild(SwiperComponent, { static: false }) quizSwiper?: SwiperComponent;

  answers = { q1: false, q2: false, q3: false, q4: false };

  generatedLink: string = 'https://azma.live/show-result/' + localStorage.getItem("token") + '?ext=true';

  resultDescription: string = "";

  resultHeader: string = '';

  voiceOvers: any = [];

  currentAudio: any;
  audioState: boolean = false;

  gender: string = localStorage.getItem('gender') ? localStorage.getItem('gender') : 'male';

  showResult: boolean = false;


  config: SwiperConfigInterface = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    centeredSlides: true,
    observeParents: true,
    observer: true,

  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.contentService.getAssessmentVO().then((response: any) => {
      this.voiceOvers = response[0]?.fields;
      this.quizSwiper.directiveRef.update();
      // console.log(this.voiceOvers);
    })
  }

  submitAnswer() {
    // console.log(this.answers);



    const body = { assessment: { ...this.answers }, type: this.getType(this.answers), time: new Date().toLocaleString() };

    // // console.log(body);

    this.authService.submitAssessment(body);
    // this.quizSwiper.directiveRef?.nextSlide();
    this.showResult = true;
    this.pauseAudio();

  }

  getType(answers: any) {

    let result: number = 0;

    Object.values(answers).forEach(val => {
      // // console.log(val);
      if (val) {
        result++
      }
    });

    if (result == 0) {

      if (localStorage.getItem("lang") == 'ar') {
        this.resultDescription = "نهنئك بأن الربو لديك متحكم به بشكل جيد، حيث لم تظهر لديك في النهار أعراض الربو أو الحاجة إلى إستخدام البخاخ الموسع للقصبات الهوائية قصيرالمفعول بشكل متكرر، أو أدى الربو إلى استيقاضك في الليل، أو إعاقة نشاطك اليومي <br><br> ننصحك بالإنتظام على أدويتك، وعدم تغيير الخطة العلاجية إلا بعد مراجعة طبيبك"
      } else {
        this.resultDescription = "We congratulate you that your Asthma is well controlled, as you did not suffer from Asthma symptoms during the daytime, frequent use of reliever inhalers (SABA), night waking, or limitation of any of your daily activity. <br><br> We advise you to continue taking your medications regularly and to avoid changing the management plan without consulting your doctor."
      }

      this.resultHeader = "Well Controlled";
      return "Well Controlled"
    } else if (result <= 2) {

      if (localStorage.getItem("lang") == 'ar') {
        this.resultDescription = "نود أن نشير بأن الربو لديك متحكم به جزئياً، حيث ظهرت لديك في النهار بعض أعراض الربو، أو استخدمت البخاخ الموسع للقصبات الهوائية قصير المفعول بشكل متكرر، وربما أدى إلى إستيقاضك في الليل، أو إعاقة نشاطك اليوم <br><br> ننصحك بالانتظام على أدويتك. وفي حال قدم لك طبيبك خطة علاج ذاتية، فننصحك بإتباعها. إذا لم تتحسن أعراض الربو خلال 2-3 يوم فمن الأفضل استشارة طبيبك المعالج خلال هذه الفترة"
      } else {
        this.resultDescription = "We would like to let you know that your Asthma is partially controlled, as you could suffer from Asthma symptoms during the daytime or frequent use of relievers  inhalers (SABA), or night waking, or limitation of any of your daily activity. <br><br> We advise you to regularly take your medications.  If your doctor provides you with a self-management plan, we advise you to follow it. If your Asthma symptoms do not improve within 2-3 days, it is recommended to consult your doctor during this period."
      }

      this.resultHeader = "Partly Controlled";
      return "Partly Controlled"
    } else if (result <= 4) {

      if (localStorage.getItem("lang") == 'ar') {
        this.resultDescription = "نود أن ننبهك بأن أعراض الربو لديك غير متحكم بها، حيث تظهر أعراض الربو لديك أثناء النهار مع استخدامك البخاخ الموسع للقصبات الهوائية قصير المفعول بشكل متكرر، ويبدو أن ذلك له تأثير على نومك باستيقاظك بشكل متكرر في الليل، ويسبب لك إعاقة لنشاطك اليومي. <br><br> ننصحك باستخدام علاجك وتطبيق خطة علاجك الذاتية إذا قدمها لك طبيبك المعالج. <br><br> كذلك ننصحك بطلب الاستشارة الطبية بشكل عاجل."
      } else {
        this.resultDescription = "We would like to inform you that your Asthma is uncontrolled as you suffer from Asthma symptoms during the daytime and use the reliever inhalers (SABA) frequently, and these symptoms seem to have impact on your frequent waking up at night and cause limitation of your daily activities. <br><br> We advise you take your medications and follow your self-management plan if provided by the attending doctor. <br><br> We advise to urgently seek medical advice."
      }

      this.resultHeader = "Uncontrolled";
      return "Uncontrolled"
    }

  }

  /* To copy Text from Textbox */
  copyLink() {
    const textToCopy = 'https://azma.live/show-result/' + localStorage.getItem("token") + '?ext=true'
    return textToCopy
    // navigator.clipboard.writeText(textToCopy)
    //   .then(() => { this.contentService.displaySuccess('Link Copied Successfully \n تم نسخ الرابط بنجاح') })
    //   .catch((error) => { this.contentService.displayError(error) })

  }

  playAudio(audioLink: any) {
    // // console.log(audioLink);
    this.currentAudio = new Audio(audioLink);
    this.currentAudio.play();
    this.audioState = true;
  }

  pauseAudio() {
    this.currentAudio?.pause();
    this.audioState = false;
  }

  ngOnDestroy() {
    this.pauseAudio();
  }

}
