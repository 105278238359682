import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  reportLink: string = '/show-result/' + localStorage.getItem("token");

  constructor(
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.contentService.hideMenu.next(false);
  }

  ngOnDestroy() {
    this.contentService.hideMenu.next(true);
  }

  navigateToReport() {
    window.open(this.reportLink, "_self");
  }

}
