<div class="container helper-div min-height-100">
  <span class="helper"></span>
  <div class="vertical-center">
    <div class="row">
      <div class="col-md-8 mx-auto col-12">
        <div class="text-center">
          <h1 class="text-white text-uppercase" translate>DashBoard</h1>
        </div>

        <div class="sections row w-100 mt-3 mx-auto">
          <div class="col-md-12 col-12 text-ceter my-2">
            <div
              class="w-100 bg-white h-100 px-2 py-4 text-center mx-auto pointer"
              style="border-radius: 10px"
              [routerLink]="['/assessment']"
            >
              <div
                class="text-center rounded-circle helper-div mx-auto"
                style="width: 70px; height: 70px"
              >
                <span class="helper"></span>
                <!-- <i class="ri-question-mark vertical-center ri-2x text-white"></i> -->
                <img
                  src="assets/images/assessment.png"
                  alt="assessment Image"
                  class="vertical-center w-100 mx-auto"
                />
              </div>
              <p class="mt-2 mb-0" translate style="font-weight: 600">
                Assessment of Asthma Control
              </p>
            </div>
          </div>

          <div class="col-md-6 col-6 text-ceter my-2">
            <div
              class="w-100 bg-white h-100 px-2 py-4 text-center mx-auto pointer"
              style="border-radius: 10px"
              [routerLink]="['/intro-video']"
            >
              <div
                class="text-center rounded-circle helper-div mx-auto"
                style="width: 70px; height: 70px"
              >
                <span class="helper"></span>
                <!-- <i class="ri-play-fill vertical-center ri-2x text-white"></i> -->
                <img
                  src="assets/images/main-video.png"
                  alt="Main Video Image"
                  class="vertical-center w-100 mx-auto"
                />
              </div>
              <p class="mt-2 mb-0" translate style="font-weight: 600">
                Main Video
              </p>
            </div>
          </div>

          <div class="col-6 text-ceter my-2">
            <div
              class="w-100 bg-white h-100 px-2 py-4 text-center mx-auto pointer"
              style="border-radius: 10px"
              (click)="navigateToReport()"
            >
              <div
                class="text-center rounded-circle bg-secondary helper-div mx-auto"
                style="width: 70px; height: 70px"
              >
                <span class="helper"></span>
                <i class="ri-history-line vertical-center ri-2x text-white"></i>
              </div>
              <p class="mt-2 mb-0" translate style="font-weight: 600">
                Previous Results
              </p>
            </div>
          </div>

          <div class="col-md-4 col-6 text-ceter my-2">
            <div
              class="w-100 bg-white h-100 px-2 py-4 text-center mx-auto pointer"
              style="border-radius: 10px"
              [routerLink]="['/articles']"
            >
              <div
                class="text-center rounded-circle helper-div mx-auto"
                style="width: 70px; height: 70px"
              >
                <span class="helper"></span>
                <!-- <i class="ri-more-fill vertical-center ri-2x text-white"></i> -->
                <img
                  src="assets/images/know-more.png"
                  alt="Know More Image"
                  class="vertical-center w-100 mx-auto"
                />
              </div>
              <p class="mt-2 mb-0" translate style="font-weight: 600">
                Know more about Asthma
              </p>
            </div>
          </div>

          <div class="col-md-4 col-6 text-ceter my-2">
            <div
              class="w-100 bg-white h-100 px-2 py-4 text-center mx-auto pointer"
              style="border-radius: 10px"
              [routerLink]="['/messages-to-asthma-patients']"
            >
              <div
                class="text-center rounded-circle helper-div mx-auto"
                style="width: 70px; height: 70px"
              >
                <span class="helper"></span>
                <!-- <i class="ri-chat-3-line vertical-center ri-2x text-white"></i> -->
                <img
                  src="assets/images/message.png"
                  alt="message Image"
                  class="vertical-center w-100 mx-auto"
                />
              </div>
              <p class="mt-2 mb-0" translate style="font-weight: 600">
                Message to Asthma Patients
              </p>
            </div>
          </div>

          <div class="col-md-4 col-6 text-ceter my-2">
            <div
              class="w-100 bg-white h-100 px-2 py-4 text-center mx-auto pointer"
              style="border-radius: 10px"
              [routerLink]="['/childhood-faqs']"
            >
              <div
                class="text-center rounded-circle bg-secondary helper-div mx-auto"
                style="width: 70px; height: 70px"
              >
                <span class="helper"></span>
                <!-- <i class="ri-user-smile-line vertical-center ri-2x text-white"></i> -->
                <img
                  src="assets/images/childhood.png"
                  alt="childhood Image"
                  class="vertical-center w-100 mx-auto"
                />
              </div>
              <p class="mt-2 mb-0" translate style="font-weight: 600">
                Childhood Asthma FAQs
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
