<div class="container-fluid main-bg position-relative">

   <app-header></app-header>

   <div (click)="closeNav()">
   <router-outlet></router-outlet>
</div>

   <img src="assets/images/az-logo-white.svg" alt="AZ" class="position-absolute desktop-only" style="bottom: 40px; right: 5%; width: 200px;">
   <img src="assets/images/az-logo-white.svg" alt="AZ" class="position-absolute mobile-only" style="bottom: 20px; right: 5%; width: 150px;">
</div>