import { Injectable } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { createClient, Entry } from 'contentful';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

const CONFIG = {
  space: '7qlyuo47ebjz',
  accessToken: 'nJ14rSsEjDrg7yfeHn1kWM017S4rUEjv6ZjarKVTDEo',

  contentTypeIds: {
    article: 'article',
    faQs: 'faQs',
    message: 'message',
    aboutUsPage: 'aboutUsPage',
    introductionContent: 'introductionContent',
    assessmentVoiceOvers: 'assessmentVoiceOvers',
    contact: 'contact'
  }
}

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken,
    // basePath: 'https://cors-anywhere.herokuapp.com/',
    // httpsAgent: 'https://cors-anywhere.herokuapp.com/'
    // proxy: { host: ' 109.125.139.172', port: 8080 }S
  });

  hideMenu = new Subject;
  updateLang = new Subject;

  constructor(
    private toastr: ToastrService
  ) { }

  async getArticles(query?: object): Promise<Entry<any>[]> {
    const res = await this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.article,
      locale: localStorage.getItem("lang")
    }, query));
    return res.items;
  }

  async getChildhoodFaqs(query?: object): Promise<Entry<any>[]> {
    const res = await this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.faQs,
      locale: localStorage.getItem("lang")
    }, query));
    return res.items;
  }

  async getMessages(query?: object): Promise<Entry<any>[]> {
    const res = await this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.message,
      locale: localStorage.getItem("lang")
    }, query));
    return res.items;
  }
  
  async getAboutUsPage(query?: object): Promise<Entry<any>[]> {
    const res = await this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.aboutUsPage,
      locale: localStorage.getItem("lang")
    }, query));
    return res.items;
  }

  async getIntroVideo(query?: object): Promise<Entry<any>[]> {
    const res = await this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.introductionContent,
      locale: localStorage.getItem("lang")
    }, query));
    return res.items;
  }

  async getAssessmentVO(query?: object): Promise<Entry<any>[]> {
    const res = await this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.assessmentVoiceOvers,
      locale: localStorage.getItem("lang")
    }, query));
    return res.items;
  }

  async getContactUsPage(query?: object): Promise<Entry<any>[]> {
    const res = await this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.contact,
      locale: localStorage.getItem("lang")
    }, query));
    return res.items;
  }

  returnHtmlFromRichText(richText: any) {

    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Error</p>';
    }
    return documentToHtmlString(richText);
  }

  getCountries() {
    return ["Saudi Arabia"];
  }

  displayError(message: string) {
    this.toastr.error(message);
  }

  displaySuccess(message: string) {
    this.toastr.success(message);
  }
}
