<div class="container helper-div min-height-100">
    <span class="helper"></span>
    <div class="vertical-center" *ngIf=" content && content.content">
        <div class="row">
            <div class="col-md-10 text-center col-12 mx-auto">
                <h1 class="text-white" translate>ABOUT AZMA </h1>
            </div>
        </div>

        <div class="row my-4">
            <div class="col-md-10 col-12 mx-auto">
                <div [innerHTML]="convertRichTextIntoHTML(content.content)" class="text-white inner-white text-left my-4"></div>
            </div>
        </div>

        <div class="row w-100 mt-5">
            <div class="col-md-6 col-xl-3 col-12 mx-auto">
                <button class="btn btn-secondary bg-transparent text-white w-100" [routerLink]="['']" translate> Back</button>
            </div>
        </div>

    </div>
</div>