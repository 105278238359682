<div class="container helper-div min-height-100">
    <span class="helper"></span>
    <div class="vertical-center">


        <div class="row">
            <div class="col-md-4 mx-auto text-center">

                <h1 class="text-white" translate>Login</h1>

                <form [formGroup]="loginForm" (ngSubmit)="login()">

                    <div class="my-4">
                        <input type="email" class="w-100 form-control" [formControlName]="'email'"
                            [placeholder]="placeholders[lang].email">
                        <span class="w-100 text-white d-block mx-2"
                            *ngIf="loginForm.get('email').invalid && (loginForm.get('email').touched || loginForm.get('email').dirty)"
                            translate>Invalid Email Address</span>
                    </div>

                    <div class="my-4">
                        <input type="password" class="w-100 form-control" [formControlName]="'password'"
                            [placeholder]="placeholders[lang].password">
                        <span class="w-100 text-white d-block mx-2"
                            *ngIf="loginForm.get('password').invalid && (loginForm.get('password').touched || loginForm.get('password').dirty)"
                            translate>Password Can't be empty</span>
                    </div>

                    <div class="my-4" *ngIf="errorMessage">
                        <p class="text-white" translate>{{ errorMessage }}</p>
                    </div>

                    <div class="my-4">
                        <button type="submit" class="w-100 btn btn-primary" translate>Login</button>
                    </div>
                </form>

                <div class="my-4">
                    <a (click)="forgotPassword()" class="text-white pointer" translate>Forgot password?</a>
                </div>

                <div class="my-4">
                    <hr class="w-25 mx-auto bg-white" style="border-top: 1px solid white">
                </div>

                <div>
                    <a class="text-white" [routerLink]="['/register']" translate>No Account? Register Now</a>
                </div>

            </div>
        </div>

    </div>
</div>
