import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {

  content: any;

  constructor(
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.contentService.getAboutUsPage().then((response: any) => {
      // console.log(response);
      this.content = response[0].fields;
      this.contentService.hideMenu.next(false);
    })
  }

  convertRichTextIntoHTML(richText: any) {
    return this.contentService.returnHtmlFromRichText(richText);
  }

  ngOnDestroy() {
    this.contentService.hideMenu.next(true);
  }

}
