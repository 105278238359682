<div class="container helper-div min-height-100">
  <span class="helper"></span>
  <div class="vertical-center max-width-88">
    <div class="row">
      <div class="col-md-10 mx-auto col-12">
        <swiper #quizSwiper *ngIf="!showResult">
          <div class="w-100">
            <div class="text-center my-4">
              <h1 class="text-white" translate>QUESTIONS</h1>
            </div>

            <div class="question bg-white p-5 position-relative">
              <div class="text-center my-4">
                <h4 translate class="mb-3">
                  Assess your asthma control level:
                </h4>
                <p translate>What is GINA Assessment of Asthma Control?</p>
                <p translate>
                  It helps you to find out the level of Asthma control in the
                  past four weeks. This App is designed to save your data if you
                  are using it periodically. You determine the level of control
                  by answering four simple questions.
                </p>
              </div>
            </div>

            <div class="answer mt-5">
              <div class="row w-100 mx-0">
                <div class="col-md-8 mx-auto col-12">
                  <div
                    class="row w-100 mx-0 justify-content-center align-items-center"
                  >
                    <div class="col-6">
                      <button
                        class="btn btn-primary w-100"
                        (click)="
                          quizSwiper.directiveRef?.nextSlide(); pauseAudio()
                        "
                        translate
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-100">
            <div class="text-center my-4">
              <h1 class="text-white" translate>QUESTIONS</h1>
            </div>

            <div class="question bg-white p-5 position-relative">
              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="gender == 'male' && !audioState"
                (click)="
                  playAudio(voiceOvers.question1MaleAudio.fields.file.url)
                "
              >
                <i class="ri-volume-up-line text-white ri-2x"></i>
              </div>

              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="gender == 'female' && !audioState"
                (click)="
                  playAudio(voiceOvers.question1FemaleAudio.fields.file.url)
                "
              >
                <i class="ri-volume-up-line text-white ri-2x"></i>
              </div>

              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="audioState"
                (click)="pauseAudio()"
              >
                <i class="ri-stop-fill text-white ri-2x"></i>
              </div>

              <div class="text-center">
                <div class="questionNumber mx-auto" style="width: fit-content">
                  <p class="text-white bg-secondary px-4 py-1">
                    <i
                      class="ri-question-line"
                      style="vertical-align: -0.2em"
                    ></i>
                    1 / 4
                  </p>
                </div>
              </div>

              <div class="text-center my-4">
                <h3 translate>
                  In the past 4 weeks, has the patient had: Daytime asthma
                  symptoms more than twice/week?
                </h3>
              </div>
            </div>

            <div class="answer mt-5">
              <div class="row w-100 mx-0">
                <div class="col-md-8 mx-auto col-12">
                  <div class="row w-100 mx-0">
                    <div class="col-6">
                      <button
                        class="btn btn-secondary w-100"
                        (click)="
                          answers.q1 = false;
                          quizSwiper.directiveRef?.nextSlide();
                          pauseAudio()
                        "
                        translate
                      >
                        No
                      </button>
                    </div>

                    <div class="col-6">
                      <button
                        class="btn btn-primary w-100"
                        (click)="
                          answers.q1 = true;
                          quizSwiper.directiveRef?.nextSlide();
                          pauseAudio()
                        "
                        translate
                      >
                        YES
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-100">
            <div class="text-center my-4">
              <h1 class="text-white" translate>QUESTIONS</h1>
            </div>

            <div class="question bg-white p-5 position-relative">
              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="gender == 'male' && !audioState"
                (click)="
                  playAudio(voiceOvers.question2MaleAudio.fields.file.url)
                "
              >
                <i class="ri-volume-up-line text-white ri-2x"></i>
              </div>

              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="gender == 'female' && !audioState"
                (click)="
                  playAudio(voiceOvers.question2FemaleAudio.fields.file.url)
                "
              >
                <i class="ri-volume-up-line text-white ri-2x"></i>
              </div>

              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="audioState"
                (click)="pauseAudio()"
              >
                <i class="ri-stop-fill text-white ri-2x"></i>
              </div>

              <div class="text-center">
                <div class="questionNumber mx-auto" style="width: fit-content">
                  <p class="text-white bg-secondary px-4 py-1">
                    <i
                      class="ri-question-line"
                      style="vertical-align: -0.2em"
                    ></i>
                    2/4
                  </p>
                </div>
              </div>

              <div class="text-center my-4">
                <h3 translate>
                  In the past 4 weeks, has the patient had: Any night waking due
                  to asthma?
                </h3>
              </div>
            </div>

            <div class="answer mt-5">
              <div class="row w-100 mx-0">
                <div class="col-md-8 mx-auto col-12">
                  <div class="row w-100 mx-0">
                    <div class="col-6">
                      <button
                        class="btn btn-secondary w-100"
                        (click)="
                          answers.q2 = false;
                          quizSwiper.directiveRef?.nextSlide();
                          pauseAudio()
                        "
                        translate
                      >
                        No
                      </button>
                    </div>

                    <div class="col-6">
                      <button
                        class="btn btn-primary w-100"
                        (click)="
                          answers.q2 = true;
                          quizSwiper.directiveRef?.nextSlide();
                          pauseAudio()
                        "
                        translate
                      >
                        YES
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-100">
            <div class="text-center my-4">
              <h1 class="text-white" translate>QUESTIONS</h1>
            </div>

            <div class="question bg-white p-5 position-relative">
              <!-- <div class="speaker position-absolute bg-primary rounded-circle text-center pointer">
                                <i class="ri-volume-up-line text-white ri-2x"></i>
                            </div> -->

              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="gender == 'male' && !audioState"
                (click)="
                  playAudio(voiceOvers.question3MaleAudio.fields.file.url)
                "
              >
                <i class="ri-volume-up-line text-white ri-2x"></i>
              </div>

              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="gender == 'female' && !audioState"
                (click)="
                  playAudio(voiceOvers.question3FemaleAudio.fields.file.url)
                "
              >
                <i class="ri-volume-up-line text-white ri-2x"></i>
              </div>

              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="audioState"
                (click)="pauseAudio()"
              >
                <i class="ri-stop-fill text-white ri-2x"></i>
              </div>

              <div class="text-center">
                <div class="questionNumber mx-auto" style="width: fit-content">
                  <p class="text-white bg-secondary px-4 py-1">
                    <i
                      class="ri-question-line"
                      style="vertical-align: -0.2em"
                    ></i>
                    3/4
                  </p>
                </div>
              </div>

              <div class="text-center my-4">
                <h3 translate>
                  In the past 4 weeks, has the patient had: Reliever (SABA) for
                  symptoms more than twice/week?
                </h3>
              </div>
            </div>

            <div class="answer mt-5">
              <div class="row w-100 mx-0">
                <div class="col-md-8 mx-auto col-12">
                  <div class="row w-100 mx-0">
                    <div class="col-6">
                      <button
                        class="btn btn-secondary w-100"
                        (click)="
                          answers.q3 = false;
                          quizSwiper.directiveRef?.nextSlide();
                          pauseAudio()
                        "
                        translate
                      >
                        No
                      </button>
                    </div>

                    <div class="col-6">
                      <button
                        class="btn btn-primary w-100"
                        (click)="
                          answers.q3 = true;
                          quizSwiper.directiveRef?.nextSlide();
                          pauseAudio()
                        "
                        translate
                      >
                        YES
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-100">
            <div class="text-center my-4">
              <h1 class="text-white" translate>QUESTIONS</h1>
            </div>

            <div class="question bg-white p-5 position-relative">
              <!-- <div class="speaker position-absolute bg-primary rounded-circle text-center pointer">
                                <i class="ri-volume-up-line text-white ri-2x"></i>
                            </div> -->

              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="gender == 'male' && !audioState"
                (click)="
                  playAudio(voiceOvers.question4MaleAudio.fields.file.url)
                "
              >
                <i class="ri-volume-up-line text-white ri-2x"></i>
              </div>

              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="gender == 'female' && !audioState"
                (click)="
                  playAudio(voiceOvers.question4FemaleAudio.fields.file.url)
                "
              >
                <i class="ri-volume-up-line text-white ri-2x"></i>
              </div>

              <div
                class="speaker position-absolute bg-primary rounded-circle text-center pointer"
                *ngIf="audioState"
                (click)="pauseAudio()"
              >
                <i class="ri-stop-fill text-white ri-2x"></i>
              </div>

              <div class="text-center">
                <div class="questionNumber mx-auto" style="width: fit-content">
                  <p class="text-white bg-secondary px-4 py-1">
                    <i
                      class="ri-question-line"
                      style="vertical-align: -0.2em"
                    ></i>
                    4/4
                  </p>
                </div>
              </div>

              <div class="text-center my-4">
                <h3 translate>
                  In the past 4 weeks, has the patient had: Any activity
                  limitation due to asthma?
                </h3>
              </div>
            </div>

            <div class="answer mt-5">
              <div class="row w-100 mx-0">
                <div class="col-md-8 mx-auto col-12">
                  <div class="row w-100 mx-0">
                    <div class="col-6">
                      <button
                        class="btn btn-secondary w-100"
                        (click)="answers.q4 = false; submitAnswer()"
                        translate
                      >
                        No
                      </button>
                    </div>

                    <div class="col-6">
                      <button
                        class="btn btn-primary w-100"
                        (click)="answers.q4 = true; submitAnswer()"
                        translate
                      >
                        YES
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper>

        <div class="w-100" *ngIf="showResult">
          <div class="text-center my-4">
            <h1 class="text-white" translate>RESULT</h1>
          </div>

          <div class="question bg-white p-5 position-relative">
            <!-- <div class="speaker position-absolute bg-primary rounded-circle text-center pointer">
                            <i class="ri-volume-up-line text-white ri-2x"></i>
                        </div> -->

            <div class="text-center my-4">
              <i
                class="ri-checkbox-circle-line ri-4x text-success"
                *ngIf="resultHeader == 'Well Controlled'"
              ></i>

              <i
                class="ri-spam-2-line ri-4x"
                *ngIf="resultHeader == 'Partly Controlled'"
                style="color: #d38c21"
              ></i>
              <i
                class="ri-spam-2-line ri-4x"
                *ngIf="resultHeader == 'Uncontrolled'"
                style="color: #d2284a"
              ></i>

              <h3 translate>{{ resultHeader }}</h3>
            </div>

            <div class="text-center my-4">
              <p [innerHTML]="resultDescription"></p>
            </div>

            <div class="text-center my-4">
              <h4 translate>Share Your Result</h4>
            </div>

            <div class="row">
              <div class="col-md-7 col-12 mb-3">
                <input
                  type="text"
                  [value]="generatedLink"
                  class="form-control w-100 py-3"
                  disabled
                />
              </div>

              <div class="col-md-5 col-12 mb-3 text-center">
                <share-buttons
                  theme="circles-dark"
                  [include]="['whatsapp', 'copy']"
                  [url]="copyLink()"
                ></share-buttons>
              </div>
            </div>
          </div>

          <div class="text-center my-4">
            <button class="btn btn-primary w-50" [routerLink]="['']" translate>
              DashBoard
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
