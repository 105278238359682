<div class="container helper-div min-height-100">
    <span class="helper"></span>
    <div class="vertical-center" *ngIf="introContent">
        <div class="row">
            <div class="text-center my-4">
                <h1 class="text-white " translate>Introduction Video</h1>
            </div>

            <div class="row w-100">
                <div class="col-md-6 col-12 my-4">
                    <div class="videoBg w-100">

                        <iframe [src]="'https://player.vimeo.com/video/' + introContent.maleVimeoId + '?loop=1&autoplay=1' | safe" width="100%" height="300"
                        frameborder="0" allow="autoplay; fullscreen" allowfullscreen *ngIf="gender == 'male' && age > 18"></iframe>

                        <iframe [src]="'https://player.vimeo.com/video/' + introContent.femaleVimeoId + '?loop=1&autoplay=1' | safe" width="100%" height="300"
                        frameborder="0" allow="autoplay; fullscreen" allowfullscreen *ngIf="gender == 'female' && age > 18"></iframe>

                        <iframe [src]="'https://player.vimeo.com/video/' + introContent.childMaleVimeoId + '?loop=1&autoplay=1' | safe" width="100%" height="300"
                        frameborder="0" allow="autoplay; fullscreen" allowfullscreen *ngIf="gender == 'male' && age < 18"></iframe>

                        <iframe [src]="'https://player.vimeo.com/video/' + introContent.childFemaleVimeoId + '?loop=1&autoplay=1' | safe" width="100%" height="300"
                        frameborder="0" allow="autoplay; fullscreen" allowfullscreen *ngIf="gender == 'female' && age < 18"></iframe>

                        <!-- <span class="helper"></span>

                        <div class="vertical-center text-center">
                            <i class="ri-play-circle-line text-white ri-4x hover pointer"></i>
                        </div> -->
                    </div>
                </div>


                <div class="col-md-6 col-12 my-4">
                    <div [innerHTML]="convertRichTextIntoHTML(introContent.introVideoDescription)" class="text-white inner-white"></div>
                </div>

            </div>

            <div class="row mt-4 w-100">
                <div class="col-md-6 col-xl-3 col-12 mx-auto">
                    <button class="btn btn-secondary bg-transparent text-white w-100" translate [routerLink]="['']" *ngIf="userMadeAssessment">Back</button>
                    <button class="w-100 btn btn-primary" translate [routerLink]="['/assessment']" *ngIf="!userMadeAssessment">Assessment</button>
                </div>
            </div>
        </div>
    </div>
</div>