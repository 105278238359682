import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  FormControl,
  FormControlName,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { EncService } from 'src/app/services/enc.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, Validators.required),
  });

  lang: string | undefined = localStorage.getItem('lang');

  placeholders = {
    en: { email: 'Email Address', password: 'Password' },
    ar: { email: 'البريد الإلكتروني', password: 'كلمه السر' },
  };

  errorMessage: string = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private firestore: AngularFirestore,
    private encService: EncService
  ) {}

  ngOnInit(): void {}

  login() {
    this.loginForm.markAllAsTouched();
    this.errorMessage = '';

    if (this.loginForm.status === 'VALID') {
      this.authService.login(this.loginForm.value).then(
        (response: any) => {
          // console.log(response);
          localStorage.setItem('token', response.user.uid);

          this.firestore
            .collection('users')
            .doc(response.user.uid)
            .valueChanges()
            .pipe(take(1))
            .subscribe((user: any) => {
              // console.log(user);
              const userData = this.encService.decrypt(user.data);
              localStorage.setItem('gender', userData.gender);
              this.router.navigate(['']);
            });
        },
        (error) => {
          // console.log(error);
          this.errorMessage = error.message;
        }
      );
    }
  }

  forgotPassword() {
    const email = prompt(
      'Please Enter Your Email Address \n الرجاء إدخال عنوان البريد الإلكتروني الخاص بك'
    );

    // console.log(email);

    if (email) {
      this.authService.resetPassword(email).then(
        (response: any) => {
          // console.log(response);
        },
        (error) => {
          // console.log(error);
          alert(error.message);
        }
      );
    }
  }
}
