<div class="container-fluid mx-0 px-0">
  <div class="container helper-div min-height-100">
    <span class="helper"></span>
    <div class="vertical-center">
      <div class="row" *ngIf="patient">
        <div
          class="col-md-10 mx-auto col-12 force-ltr"
          style="text-align: left"
          id="map-col"
        >
          <h4 class="text-white">
            <span translate>- Name: </span> <span>{{ patient.name }}</span>
          </h4>
          <h4 class="text-white">
            <span translate>- Birth Date: </span>
            <span>{{ patient.birth_date | date }}</span>
          </h4>
          <h4 class="text-white text-capitalize">
            <span translate>- Gender: </span> <span>{{ patient.gender }}</span>
          </h4>

          <br />
          <br />

          <div class="row">
            <div class="col-md-8 col-12 mb-3">
              <input
                type="text"
                [value]="generatedLink"
                class="form-control w-100 py-3"
                disabled
              />
            </div>

            <div class="col-md-4 col-12 mb-3">
              <button
                class="w-100 btn btn-primary py-3 pointer"
                (click)="copyLink()"
                translate
              >
                Copy Link
              </button>
            </div>
          </div>

          <div class="bg-white">
            <div class="px-3 py-2" *ngIf="!showOnlyOneResult">
              <h4>3 - Fully Controlled</h4>
              <h4>2 - Partly Controlled</h4>
              <h4>1 - Uncontrolled</h4>
            </div>

            <google-chart
              [type]="chartData.type"
              [data]="chartData.data"
              [columns]="chartData.columnNames"
              [options]="chartData.options"
              [width]="chartData.width"
              [height]="chartData.height"
              *ngIf="!showOnlyOneResult"
              class="max-width-88"
            >
            </google-chart>
          </div>

          <h4 class="text-white">In the past 4 weeks, has the patient had:</h4>

          <br /><br />

          <div *ngFor="let assessment of patient.assessments">
            <h4 class="text-white">
              <span translate></span> <span>{{ assessment.time }}</span>
            </h4>
            <br />
            <h4 class="text-white">
              <span translate>- Type: </span> <span>{{ assessment.type }}</span>
            </h4>
            <h4 class="text-white">
              <span translate
                >- Daytime asthma symptoms more than twice/week?
              </span>
              <span>{{ assessment.assessment.q1 ? "Yes" : "No" }}</span>
            </h4>
            <h4 class="text-white">
              <span translate>- Any night waking due to asthma? </span>
              <span>{{ assessment.assessment.q2 ? "Yes" : "No" }}</span>
            </h4>
            <h4 class="text-white">
              <span translate
                >- Reliever (SABA) for symptoms more than twice/week?
              </span>
              <span>{{ assessment.assessment.q3 ? "Yes" : "No" }}</span>
            </h4>
            <h4 class="text-white">
              <span translate>- Any activity limitation due to asthma? </span>
              <span>{{ assessment.assessment.q4 ? "Yes" : "No" }}</span>
            </h4>
            <br /><br /><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
