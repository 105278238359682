import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-childhood-faqs',
  templateUrl: './childhood-faqs.component.html',
  styleUrls: ['./childhood-faqs.component.scss']
})
export class ChildhoodFaqsComponent implements OnInit, OnDestroy {

  faqs: any;
  lang = localStorage.getItem("lang")

  constructor(
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.contentService.hideMenu.next(false);

    this.contentService.getChildhoodFaqs().then((response: any) => {
      // console.log(response);

      this.faqs = response;
    }, error => {
      // console.log(error);
    })
  }

  ngOnDestroy() {
    this.contentService.hideMenu.next(true);
  }

}
