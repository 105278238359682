import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  contactPageContent: any

  constructor(
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.contentService.hideMenu.next(false);

    this.contentService.getContactUsPage().then((response: any) => {
      this.contactPageContent = response[0].fields;
      // console.log(this.contactPageContent);
    })

  }

  ngOnDestroy() {
    this.contentService.hideMenu.next(true);
  }

}
