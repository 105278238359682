<div class="container helper-div min-height-100">
    <span class="helper"></span>
    <div class="vertical-center" *ngIf="user">

        <div class="row">
            <div class="col-xl-10 col-12 bg-white mx-auto" style="border-radius: 50px;">


                <div class="row">
                    <div class="col-md-5 col-12 helper-div">
                        <span class="helper"></span>
                        <div class="p-4 text-center border-right force-ltr vertical-center"
                            style="height: fit-content;">

                            <div class="position-relative">
                                <img [src]="profileImage" alt="Profile Img" class="w-50 my-3 pointer">


                                <div class="cameraIcon position-absolute bg-primary rounded-circle text-center pointer" (click)="uploadInput.click()">
                                    <i class="ri-camera-fill text-white ri-2x"></i>
                                </div>

                            </div>

                            <input type="file" accept="*.png.jpg" (change)="uploadImage($event)" hidden #uploadInput>

                            <h4>{{ user.name }}</h4>
                            <h5>{{ user.email }}</h5>


                        </div>
                    </div>


                    <div class="col-md-7 col-12">
                        <div class="py-5 px-4">

                            <h5 class="secondary-color mt-4" translate>Name</h5>

                            <p> {{user.name}} </p>

                            <hr>


                            <h5 class="secondary-color mt-4" translate>Email Address</h5>

                            <p> {{user.email}} </p>

                            <hr>

                            <h5 class="secondary-color mt-4" translate>Phone</h5>

                            <p> {{user.phone}} </p>

                            <hr>


                            <h5 class="secondary-color mt-4" translate>Gender</h5>

                            <p translate> {{user.gender}} </p>

                            <hr>

                            <h5 class="secondary-color mt-4" translate>Date of Birth</h5>

                            <p> {{user.birth_date}} </p>

                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="row w-100 mt-5">
            <div class="col-md-6 col-xl-3 col-12 mx-auto">
                <button class="btn btn-secondary bg-transparent text-white w-100" [routerLink]="['']" translate>
                    Back</button>
            </div>
        </div>
    </div>
</div>