import { Component, OnInit } from '@angular/core';
import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  articles: any;
  lang = localStorage.getItem("lang");

  constructor(
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.contentService.hideMenu.next(false);

    this.contentService.getMessages().then((response: any) => {
      // console.log(response);

      this.articles = response;
    }, error => {
      // console.log(error);
    })
  }

  ngOnDestroy() {
    this.contentService.hideMenu.next(true);
  }

}
