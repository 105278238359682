import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ContentService } from '../services/content.service';
import { EncService } from '../services/enc.service';

@Component({
  selector: 'app-intro-video',
  templateUrl: './intro-video.component.html',
  styleUrls: ['./intro-video.component.scss'],
})
export class IntroVideoComponent implements OnInit {
  introContent: any;
  gender: any;
  age: any;
  userMadeAssessment: boolean = false;

  constructor(
    private contentService: ContentService,
    private firestore: AngularFirestore,
    private encService: EncService
  ) {}

  ngOnInit(): void {
    this.contentService.hideMenu.next(false);

    this.contentService.getIntroVideo().then((response: any) => {
      // console.log(response[0].fields);
      this.introContent = response[0].fields;
    });

    this.firestore
      .collection('users')
      .doc(localStorage.getItem('token'))
      .valueChanges()
      .pipe(take(1))
      .subscribe((user: any) => {
        // console.log(user[0]);
        const userData = this.encService.decrypt(user.data);
        this.gender = userData.gender;
        this.age = userData.age ?? 19;
        if (userData.assessments.length) {
          this.userMadeAssessment = userData.assessments.length ? true : false;
        }
      });
  }

  convertRichTextIntoHTML(richText: any) {
    return this.contentService.returnHtmlFromRichText(richText);
  }

  ngOnDestroy() {
    this.contentService.hideMenu.next(true);
  }
}
