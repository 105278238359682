import { Injectable, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { EncService } from './enc.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    private router: Router,
    private encService: EncService
  ) {}

  register(body: any) {
    return this.auth.createUserWithEmailAndPassword(body.email, body.password);
  }

  getUid() {
    return localStorage.getItem('token') ? localStorage.getItem('token') : null;
  }

  saveUserToDatabase(user: any, id: any) {
    return this.firestore
      .doc('users/' + id)
      .set({ data: this.encService.encrypt(user) });
  }

  login(body: any) {
    return this.auth.signInWithEmailAndPassword(body.email, body.password);
  }

  resetPassword(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }

  logout() {
    localStorage.clear();
    this.auth.signOut();
    this.router.navigate(['/intro']);
  }

  checkForIntro() {
    return localStorage.getItem('lang') ? true : false;
  }

  checkForUser() {
    // return this.auth.currentUser;
    return this.auth.authState;
  }

  submitAssessment(body: any) {
    this.firestore
      .collection('users')
      .doc(localStorage.getItem('token'))
      .valueChanges()
      .pipe(take(1))
      .subscribe((user: any) => {
        const userData = this.encService.decrypt(user.data);
        const assessments = userData.assessments ? userData.assessments : [];
        const newAssessment = body;
        assessments[Object.keys(assessments).length] = newAssessment;
        // console.log(assessments);

        this.firestore
          .doc('users/' + localStorage.getItem('token'))
          .update({ data: this.encService.encrypt(userData) });
      });
  }
}
