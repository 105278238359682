import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit, OnDestroy {

  lang: any;

  constructor(
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private contentService: ContentService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  changeLanguage(lang: string) {
    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    localStorage.setItem("lang", lang);
    this.changeCssFile(lang);
    this.router.navigate(['/login']);
  }

  changeCssFile(lang: string) {
    let headTag = this.document.getElementsByTagName("head")[0] as HTMLHeadElement;
    let existingLink = this.document.getElementById("langCss") as HTMLLinkElement;
    let bundleName = lang === "ar" ? "arabicStyle.css" : "englishStyle.css";
    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      let newLink = this.document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.type = "text/css";
      newLink.id = "langCss";
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }

    this.lang = lang
  }

  ngOnDestroy() {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en");
    }

    this.contentService.updateLang.next(this.lang);
  }

}
