<div class="container pt-2" style="z-index: 10;">
    <div class="row">

        <div class="col-6 mobile-only text-left helper-div" >
            <!-- Use any element to open the sidenav -->
            <span class="helper"></span>
            <i class="ri-menu-line text-white vertical-center ri-2x" *ngIf="!hideMenu" (click)="openNav()"></i>
        </div>

        <div class="col-xl-4 col-6 col-md-4 offset-md-2 offset-xl-0">
            <img src="assets/images/stc-white.png" alt="STC - Logo" class="w-100" [routerLink]="['']">
        </div>

        <div class="col-md-8  desktop-only helper-div" *ngIf="!hideMenu">
            <span class="helper"></span>
            <div class="vertical-center">


                <a [routerLink]="['']" [routerLinkActive]="'underlined'" [routerLinkActiveOptions]="{ exact: true }"
                    class="menu-item d-inline-block mx-3 " translate>Dashboard</a>

                <a [routerLink]="['/profile']" [routerLinkActive]="'underlined'"
                    [routerLinkActiveOptions]="{ exact: true }" class="menu-item mx-3 d-inline-block"
                    translate>Profile</a>

                <a [routerLink]="['/about']" [routerLinkActive]="'underlined'"
                    [routerLinkActiveOptions]="{ exact: true }" class="menu-item mx-3 d-inline-block"
                    translate>About</a>

                <a [routerLink]="['/contact-us']" [routerLinkActive]="'underlined'"
                    [routerLinkActiveOptions]="{ exact: true }" class="menu-item mx-3 d-inline-block" translate>Contact
                    Us</a>

                <a class="menu-item d-inline-block pointer mx-3 " (click)="logout()" translate>Logout</a>

                <a class="menu-item d-inline-block pointer cairo mx-3 " (click)="changeLanguage('ar')"
                    *ngIf="lang == 'en'">العربية</a>
                <a class="menu-item d-inline-block pointer cairo mx-3 " (click)="changeLanguage('en')"
                    *ngIf="lang == 'ar'">English</a>
            </div>
        </div>

    </div>
</div>



<div id="mySidenav" class="sidenav " style="z-index: 10;">
    <!-- <a href="javascript:void(0)" class="closebtn text-white" (click)="closeNav()">&times;</a> -->
    <a [routerLink]="['']" [routerLinkActive]="'underlined'" [routerLinkActiveOptions]="{ exact: true }"
        class="menu-item d-block mx-3 " (click)="closeNav()" translate>Dashboard</a>


    <a [routerLink]="['/profile']" [routerLinkActive]="'underlined'" [routerLinkActiveOptions]="{ exact: true }"
        class="menu-item mx-3 d-block" (click)="closeNav()" translate>Profile</a>

    <a [routerLink]="['/about']" [routerLinkActive]="'underlined'" [routerLinkActiveOptions]="{ exact: true }"
        class="menu-item mx-3 d-block" (click)="closeNav()" translate>About</a>

    <a [routerLink]="['/contact-us']" [routerLinkActive]="'underlined'" [routerLinkActiveOptions]="{ exact: true }"
        class="menu-item mx-3 d-block" (click)="closeNav()" translate>Contact
        Us</a>

    <a class="menu-item d-block pointer mx-3 " (click)="logout(); closeNav()" translate>Logout</a>

    <a class="menu-item d-block pointer cairo mx-3 " (click)="changeLanguage('ar')" *ngIf="lang == 'en'">العربية</a>
    <a class="menu-item d-block pointer cairo mx-3 " (click)="changeLanguage('en')" *ngIf="lang == 'ar'">English</a>
</div>